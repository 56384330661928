html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;

  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.bg_checker {
  background-image:
    linear-gradient(45deg, #ccc 25%, transparent 25%),
    linear-gradient(-45deg, #ccc 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #ccc 75%),
    linear-gradient(-45deg, transparent 75%, #ccc 75%);
  background-size: 40px 40px;
  background-position: 0 0, 0 20px, 20px -20px, -20px 0px;
}

.canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.drawer {
  --drawer-padding: 80px;

  position: fixed;
  width: 100%;
  height: calc(100% - var(--drawer-padding));
  left: 0;
  top: var(--drawer-padding);

  transform: translateY(calc(100% - 0px));
  transition: transform 0.5s ease;
  will-change: transform;
}

.drawer .bar {
  margin-top: -88px;
  height: 88px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "left center right";
  align-items: start;
  pointer-events: none;
}
.drawer .bar button {
  pointer-events: all;
}
.drawer .bar > div {
  flex-grow: 1;
}
.drawer .bar .left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  grid-area: left;
}
.drawer .bar .right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  grid-area: right;
}
.drawer .bar .center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  grid-area: center;
}
.drawer .bar .left > button { margin-left: 12px; }
.drawer .bar .right > button { margin-right: 12px; }
.drawer .bar .center > button { margin: 0 6px; }
.drawer .bar .center0 > button { margin: 0 6px; }

@media (max-width: 560px) {
  .drawer {
    --drawer-padding: 148px;
  }
  .drawer .bar {
    margin-top: -166px;
    height: 166px;
    grid-template-columns: 1fr 1fr;    
    grid-template-areas:
      "left right"
      "center center";
  }
}

.drawer .content {
  background: #fafafa;
  border-radius: 15px 15px 0px 0px;
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
  height: 100%;
  overflow: hidden;
}

.gallerylist {
  display: grid;
  column-gap: 24px;
  row-gap: 24px;
  margin-bottom: 24px;

  grid-template-columns: 1fr 1fr;
}


@media (min-width: 600px) {
  .gallerylist {
    grid-template-columns: 1fr 1fr 1fr;
  }  
}
@media (min-width: 780px) {
  .gallerylist {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (min-width: 960px) {
  .gallerylist {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}

.gallerylist .galleryitem {
  overflow: hidden;
  cursor: pointer;
  user-select: none;
}

.gallerylist .galleryitem .image {
  width: 100%;
  background-repeat: no-repeat;
  background-position-y: center;
  background-size: 100%;
}

.gallerylist .galleryitem .image::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.gallerylist .galleryitem .label {
  box-sizing: border-box;
  margin-top: -64px;
  height: 64px;
  padding: 12px;
  
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  background-image: linear-gradient(to top, rgba(255,255,255,1), rgba(255,255,255,0));
}

.content_tabs {
  height: 48px;
  background-color: #e0e0e0;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.content_tabs .MuiTab-wrapper {
  font-weight: bold;
}

.content_wrap {
  box-sizing: border-box;
  padding-top: 24px;
  height: 100%;
  overflow-y: auto;
}
.content_wrap > .MuiContainer-root {
  display: flex;
  flex-direction: column;
}

.submitform .image {
  background-color: #bdbdbd;
  background-repeat: no-repeat;
  background-position-y: center;
  background-size: 100%;

  width: 256px;
  height: 256px;
  border-radius: 4px;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: 12px;
}

.colormenu  {
  position: absolute;
  /* top: 12px; */
  right: 12px;
}
.colormenu .MuiFab-extended {
  height: 56px;
  border-radius: 28px;
}
.colormenu input[type="color"] {
  margin: 0 6px;
}
.colormenu .colorpanel {
  position: absolute;
  right: 0;
  width: 56px;
  transition: width 0.5s ease;
}
.colormenu .colorpanel.open {
  width: 202px;
}
.colormenu .colorpanel .MuiFab-label {
  justify-content: flex-start;
  overflow: hidden;
}
